import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { green, grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import CoursesActions from '../../Redux/CoursesRedux';
import NoMatch404 from '../NoMatch404';
import NoMatch401 from '../NoMatch401';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3, 0),
    },
  },
  card: {
    padding: theme.spacing(10),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    marginBottom: theme.spacing(3),
    '& .MuiStepIcon-completed': {
      color: green[600],
    },
    '& .MuiStepIcon-active': {
      color: grey[600],
    },
  },
  title: {
    color: theme.current.primary.main,
    marginBottom: theme.spacing(3),
  },
  btn: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  btnOutlined: {
    color: theme.current.primary.main,
    borderColor: theme.current.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.current.primary.dark,
      borderColor: theme.current.primary.dark,
    },
  },
  img: { height: 237, width: 237 },
  avatar: {
    backgroundColor: grey[600],
    width: 24,
    height: 24,
    fontSize: 12,
  },
}));

const ShowCourseUnitSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { course_slug: courseSlug, chapter_id: chapterId } = match.params;

  const course = useSelector(state => state.courses.course);
  const isFetching = useSelector(state => state.courses.isFetching.getCourse);
  const errors = useSelector(state => state.courses.errors.getCourse);
  const courseFetch =
    !isFetching && (!errors || errors.course_slug !== courseSlug) && course?.slug === courseSlug;

  React.useEffect(() => {
    if (!course || course.slug !== courseSlug) {
      dispatch(CoursesActions.getCourseRequest(courseSlug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  if (!isFetching && errors?.status === 404 && errors?.course_slug === courseSlug) {
    return <NoMatch404 />;
  }

  if (!isFetching && errors?.status === 401 && errors?.course_slug === courseSlug) {
    return <NoMatch401 />;
  }

  const getNextChapter = () => {
    let allChapters = [];
    course.units.forEach(unit => (allChapters = allChapters.concat(unit.chapters)));
    const currentChapterIndex = allChapters.findIndex(chapter => `${chapter.id}` === chapterId);
    const nextChapter = allChapters[currentChapterIndex + 1];
    return nextChapter;
  };
  const nextChapterId = getNextChapter()?.id;
  const currentUnit = course.units.find(unit =>
    unit.chapters.find(chapter => `${chapter.id}` === chapterId),
  );

  const StepIcon = unit => {
    const isUnitOngoing = unit?.chapters?.find(chapter => !chapter.terminated);
    return (
      <Grid>
        {isUnitOngoing ? (
          <Avatar className={classes.avatar}>{unit.sort_index + 1}</Avatar>
        ) : (
          <CheckCircleIcon sx={{ fontSize: 28 }} style={{ fill: green[600] }} />
        )}
      </Grid>
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {courseFetch && (
        <Grid className={classes.layout}>
          <Card className={classes.card} align="center">
            <Typography variant="h1" className={classes.title}>
              {t('WELLDONE')}
            </Typography>
            <img alt={t('WELLDONE')} src={Images.app.unit_course_success} className={classes.img} />
            <Stepper
              alternativeLabel
              activeStep={currentUnit.sort_index + 1}
              className={classes.stepper}
            >
              {course?.units?.map(unit => (
                <Step key={unit.id}>
                  <StepLabel StepIconComponent={() => StepIcon(unit)}>
                    <Hidden only={['xs']}>{`${unit.sort_index + 1}. ${unit.title}`}</Hidden>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid>
              <Button
                variant="outlined"
                className={classes.btnOutlined}
                onClick={() => history.push(`/formations/${course?.slug}`)}
              >
                {t('APP.COURSES.UNIT_SUCCESS.BACK')}
              </Button>
              {nextChapterId && (
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={() =>
                    history.push(`/formations/${course?.slug}/chapitres/${nextChapterId}`)
                  }
                >
                  {t('APP.COURSES.UNIT_SUCCESS.CONTINUE')}
                </Button>
              )}
            </Grid>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default ShowCourseUnitSuccess;
